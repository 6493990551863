
import DefaultSetting from "@/components/default/DefaultSetting";
import DomainWhiteList from "@/components/default/DomainWhiteList";

export default {
  components: {
    DefaultSetting,
    DomainWhiteList,
  },
  data() {
    return {
      tabs: "defaultSetting",
    }
  },
}
